import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate  } from 'react-router-dom';


const NavBarPublication = () => {

  const navigate = useNavigate();
  const goBack = () => {
        navigate(-1);
  };

  return (
  <nav className="navbar fixed-top">
    <div className='container'>
      <div className="d-flex">
        <Link className='title fw-bolder' onClick={goBack} >
            <i className="fa-solid fa-arrow-left me-2"></i> Publication
        </Link>
        <h5 className='fs-6 ms-3 mt-1 fw-bold'></h5>
      </div>
      <div className="navbar-right">
        {/**
         * 
        <a href="mailto:contact@fleetbo.io" className="cart-icon">
          <img src={assistant} className="assistant" alt="logo" /> 
        </a>
        */}
      </div>
    </div>
  </nav>
  );
};


export default NavBarPublication;