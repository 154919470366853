import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Tab1 from './Tab1/index';
import Tab2 from './Tab2/index';
import Tab3 from './Tab3/index';
import MobileTabs from './Tabs/index';
import { Link } from 'react-router-dom';


const AppLauncher = () => {

    //const [activeComponent, setActiveComponent] = useState('A');
	const [activeComponent, setActiveComponent]   = useState(localStorage.getItem('activeTab') || 'A');

   // Fonction pour changer l'onglet actif et mémoriser dans localStorage
    const handleTabChange = (tab) => {
		setActiveComponent(tab);
		localStorage.setItem('activeTab', tab); // Sauvegarde l'onglet dans localStorage
    };

    useEffect(() => {
		// Lors de l'initialisation, on vérifie si un onglet est déjà dans localStorage
		const savedTab = localStorage.getItem('activeTab');
		if (savedTab) {
		  setActiveComponent(savedTab);
		}
    }, []);

    const handleClose = async (e) => {
        //setShow(false);
        //navigate('/app');
    }

    
    return (

        <div className='App'>
            {activeComponent === 'A' && <Tab1 />}
            {activeComponent === 'B' && <Tab2 />}
            {activeComponent === 'C' && <Tab3 />}

            <footer className="mobile-tabs">
                <Link className='' onClick={() => handleTabChange('A')}>
                    {activeComponent === 'A' && <i className="fa-solid fa-home icon-footer active pt-3 pb-3 fs-3"></i> }
                    {activeComponent !== 'A' && <i className="fa-solid fa-home icon-footer pt-3 pb-3 fs-3" style={{ color: '#727171'}}></i> }
                    <br />
                </Link>
            
                <Link className='' onClick={() => handleTabChange('B')}>
                    {activeComponent === 'B' && <i className="fa-solid fa-photo-film icon-footer active pt-3 pb-3 fs-3"></i> }
                    {activeComponent !== 'B' && <i className="fa-solid fa-photo-film icon-footer pt-3 pb-3 fs-3" style={{ color: '#727171'}}></i> }
                    <br />
                </Link>

                <Link className='' onClick={() => handleTabChange('C')}>
                    {activeComponent === 'C' && <i className="fa-solid fa-clock-rotate-left icon-footer active focus-color pt-3 pb-3 fs-3"></i> }
                    {activeComponent !== 'C' && <i className="fa-solid fa-clock-rotate-left icon-footer pt-3 pb-3 fs-3" style={{ color: '#727171'}}></i> }
                    <br />
                </Link>
            </footer> 
        </div>
         

    )
};

export default AppLauncher;
