import React, { useEffect, useState } from "react";
import NavBarBell from './NavBarBell';
//import './tab2.css';
import { Link } from 'react-router-dom';


// Composant A
const ComponentA = () => {

	const [documentsData, setDocumentsData] = useState(
		JSON.parse(localStorage.getItem('data')) || null
	);
	//const [isLoading, setIsLoading] = useState(true);
	const [isLoading, setIsLoading] = useState(!documentsData);  // Montre un loader si les données ne sont pas en cache
    
    useEffect(() => {
		// Fonction pour recevoir les données depuis Kotlin
		window.getDocuments = (data) => {
		  try {
			// Essayer de parse le JSON reçu si c'est une chaîne
			const parsedData = JSON.parse(data); // Assurez-vous de parser les données si elles sont envoyées en tant que chaîne JSON

			// Vérifier si le document est valide
			if (parsedData) {
			  const newData = parsedData; // Utiliser les données directement
			  const cachedData = JSON.parse(localStorage.getItem('data')) || {}; // Récupérer les données en cache depuis le localStorage

			  // Comparer les nouvelles données avec celles en cache
			  if (JSON.stringify(newData) !== JSON.stringify(cachedData)) {
				setDocumentsData(newData);  // Mettre à jour l'état
				localStorage.setItem('data', JSON.stringify(newData));  // Mettre à jour le localStorage
				setIsLoading(false); // Arrêter le loader une fois que les données sont récupérées
			  }
			} else {
			  console.log('Aucun document trouvé ou erreur de données !');
			  setIsLoading(false);
			}
		  } catch (error) {
			console.error('Erreur lors du traitement des données :', error);
			setIsLoading(false);
		  }
		};

		// Définir la fonction dans l'objet window pour l'appeler depuis Kotlin
		//window.getDocument = getDocument;

		// Appeler la fonction Kotlin via l'interface JavaScript
		if (window.Android && typeof window.Android.getDataFromColl === 'function') {
		  window.Android.getDataFromColl('photos');
		} else {
		  console.error('Interface Android non disponible');
		}

		// Nettoyer l'effet lorsqu'il est démonté
		return () => {
		  window.getDocuments = null;  // Supprimer la fonction de l'objet window pour éviter des fuites de mémoire
		};
    }, []); // Dépendance vide pour s'exécuter uniquement au montage et démontage
	

	if (isLoading) {
		return <div className="fs-6 fw-normal">Chargement...</div>;
	}

    return( 
		<div>

		    {documentsData ? (
			documentsData.map((item, index) => (
			  <div key={index}>
				<Link className="text-selected" to={`/images/${item.id}`}>
				  <i className="fa-solid fa-image me-1"></i> {item.title}
				</Link>
				<hr />
			  </div>
			))
		    ) : (
				<p>Aucun document disponible</p>
			)}

		    <Link to='/albums'><i className="fa fa-add bg-warning floating text-white p-4 fs-3 me-1"></i></Link>
		</div>
    );
};

// Composant B
const ComponentB = () => {
  return <div><h6>Pas de vidéos pour l'instant</h6></div>;
};


const Tab2 = () => {

  // État pour déterminer quel composant afficher
  const [activeComponent, setActiveComponent] = useState('A');

  return (
    <div className='App'>
         <NavBarBell/>
         <br />  <br /> <br />
         <div className="container">
            <br />
            <div className="text-center d-flex box-gallery">
              <div className=''>
                <Link className='text-media text-dark'onClick={() => setActiveComponent('A')}>Photos</Link>
                {activeComponent === 'A' && <div><hr></hr></div> }
              </div>
              <div>
              <Link className='text-media text-dark'onClick={() => setActiveComponent('B')}>Vidéos</Link>
              {activeComponent === 'B' && <div><hr></hr></div> }
              </div>
            </div>

            <div className="container box-selected">
                {/* Affiche le composant en fonction du bouton cliqué */}
                {activeComponent === 'A' && <ComponentA />}
                {activeComponent === 'B' && <ComponentB />}
            </div>
        </div>

    </div>
  )
};

export default Tab2;
