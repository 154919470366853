

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore'; // Assurez-vous d'importer onSnapshot et doc

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(); // Initialisation à false par défaut
  const navigate = useNavigate();

  // Récupérer 'userId' depuis localStorage
  //const userId = localStorage.getItem('userId'); 
  const userId = 'VdaEMM8hrHaAcwGSuuCibn4bZ233';

  useEffect(() => {
    if (!userId) {
      navigate('/un'); // Redirige si la clé n'existe pas
      alert('No such document initial!');
      setIsLoading(false);
      return; // Terminer l'effet ici
    }

    // Accès au document Firestore
    const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', userId);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
		if (!data.logged){
			console.log('Not logged !');
			navigate('/auth'); // Redirection si le document n'existe pas
		}
        setIsLogged(data.logged); // Mise à jour du statut de connexion
      } else {
        console.log('No such document!');
        navigate('/un'); // Redirection si le document n'existe pas
      }
      setIsLoading(false); // Met à jour l'état de chargement après avoir récupéré les données
    });

    // Nettoyage de l'abonnement à Firestore lorsque le composant est démonté
    return () => unsubscribe();

  }, [userId, navigate]); // Dépendances: userId et navigate

  // Afficher un indicateur de chargement pendant la vérification
  if (isLoading) {
    return (
      <div className="App-header fs-3 fw-normal text-dark p-5">
        <Spinner animation="grow" variant="warning" role="status">
          <span className="visually-hidden text-light">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // Afficher les enfants si l'utilisateur est connecté
  return isLogged ? children : null; // Affiche le contenu protégé uniquement si l'utilisateur est connecté
};

export default ProtectedRoute;
