import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './../../images/logo.png';
import { db, auth } from "./../firebase";
import { onSnapshot, doc } from "firebase/firestore";

const NavBarHome = () => {

  const [documentData, setDocumentData] = useState(null);
  const [isLoading, setIsLoading] = useState(!documentData);  // Montre un loader si les données ne sont pas en cache

  /*
  useEffect(() => {
    const userId =  localStorage.getItem('userId');
    const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', userId);  // Remplacer 'items' et 'specificDocumentId' par vos valeurs
    // Écouter les modifications du document en temps réel
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
        const newData = docSnap.data();  // Récupérer les nouvelles données
        const data = docSnap.data().role;
        setDocumentData(data)
        setIsLoading(false);  // Arrêter le loader une fois que les données sont récupérées
        } else {
        //console.log('No such document!');
        setIsLoading(false);  // Arrêter le loader même si le document n'existe pas
        }
    });

    return () => unsubscribe();  // Nettoyer l'écouteur lorsque le composant est démonté
  }, []);
  */


  return (
  <nav className="navbar fixed-top">
    <div className='container'>
      <div className="">
        <Link className='title' to="/">
          <img src={logo} className='me-2' style={{ width: '30px' }} alt="logo" /> 
          Bandja
        </Link>
      </div>
      <div className="navbar-right">
        {/**
         * 
        <a href="mailto:contact@fleetbo.io" className="cart-icon">
          <img src={assistant} className="assistant" alt="logo" /> 
        </a>
        */}
        {documentData === 'admin' && ( 
          <Link to="/publication" className="text-secondary fw-bolder me-4" aria-label="User Account">
             <i className="fa-solid fa-plus-circle me-2"></i>
          </Link>
        )}    
        <Link to="/" className="text-secondary fw-bolder me-2" style={{ textDecoration: 'none'}} aria-label="User Account">
          En 
        </Link>
      </div>
    </div>
  </nav>
  );
};


export default NavBarHome;