import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


const Redirect = () => {

    const navigate = useNavigate();
    //const [userID, setUserID] = useState(null);
	const [isLoading, setIsLoading] = useState(true); // Pour gérer l'état de chargement


    //localStorage.setItem('userId', 'VdaEMM8hrHaAcwGSuuCibn4bZ233');
    useEffect(() => {
		const timer = setTimeout(() => {
		  const storedData = localStorage.getItem('userId'); // Simulez la récupération de données stockées
		  const loggedIn   = localStorage.getItem('logged'); // Simulez la récupération de données stockées
		  if (storedData) {
			console.log('Redirect App'); // Log pour le débogage
			if(loggedIn === 'true') {
			   navigate('/app'); // Redirection vers l'application
			   setIsLoading(false); // Met à jour l'état de chargement
			} else {
			   navigate('/auth');
			   setIsLoading(false); // Met à jour l'état de chargement
			}
		  } 
		}, 500); // Délai de 2 secondes
		// Cleanup pour éviter les fuites de mémoire si le composant est démonté
		return () => clearTimeout(timer);
	}, [navigate]); // Ajoutez navigate comme dépendance
	
	
    /*
    useEffect(() => {
      // fonction pour récupérer les données du localstorage
        const handleStorageChange = () => {
          const storedData   = 'VdaEMM8hrHaAcwGSuuCibn4bZ233';
		  const loggedIn     = true;
		  const timer = setTimeout(() => {
		  // Code à exécuter après 2.5 secondes
		  //console.log('2 secondes se sont écoulées');
		    const loggedIn   = true;
		    if(loggedIn === true) {
				//alert('REDIRECT TO HOME')
                navigate('/app');
            }
		    setIsLoading(false);
		    }, 2500); // 2000 millisecondes = 2.5 secondes
		// Cleanup pour éviter les fuites de mémoire si le composant est démonté
		return () => clearTimeout(timer);
		
         
        };
    
        // vérifier si les données sont présentes lors du premier chargement 
        handleStorageChange();

        //Ajouter un écouteur d'évènement sur le storage
        window.addEventListener('storage', handleStorageChange);

        // Nettoyage de l'écouteur pour éviter les fuites de mémoire
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
    }, []);  // le tableau vide [] indique que l'effet ne se produit qu'au chargement initial
    */
	
	// Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
    if (isLoading) {
		return <div>
			<><div className="App-header fs-3 fw-normal text-dark p-5">
				<Spinner animation="grow" variant="warning" role="status">
					<span className="visually-hidden text-light">Loading...</span>
				</Spinner>
			</div></> 
		</div>;
    }

    return (
        <div className='App'>
            <div className='App-header'>
               
            </div>
        </div>
    )
};

export default Redirect;
