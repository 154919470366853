import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './../images/logo.png';
import Spinner from 'react-bootstrap/Spinner';


const ConfirmAuth = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
	
	//const [name, setName] = useState('');
	//const [texte, setTexte] = useState('');
	//const [country, setCountry] = useState('');
	
	const [isLoading, setIsLoading] = useState(true); // Pour gérer l'état de chargement de la page
    const [contact, setContact] = useState(null);
	
	const [Loading, setLoading] = useState(false); // Pour gérer l'état de chargement des données
	

	//appeler cette fonction depuis le code Kotlin dans le WebView.
	window.updateContact = (data) => {
		setLoading(false);
		setContact(data);
	};
	
	const sendMessageToKotlin = () => {
		setLoading(true);
		if (window.Android) {
			window.Android.getDataDoc('C5tPTz0cy8fbWhMzOnCa'); // Appel de la méthode Kotlin
		} else {
			console.log("Android interface is not available");
			setLoading(false); // Arrêter le chargement si l'interface n'est pas disponible
		}
	};

	// Récupérer les données passées depuis Android
    const contacts = window.contacts || {};
	const sendMessageToKotlin2 = () => {
        // Appelle la méthode Kotlin via l'interface JavaScript
        if (window.Android) {
			const timer = setTimeout(() => {
				// Mettre à jour l'état avec les données récupérées
				setData(contacts);
				setLoading(false);
			}, 500); // 500 millisecondes = 0.5 secondes
            window.Android.getData(); // Appeler la fonction Kotlin
        } else {
            console.error('Android interface not found.');
        }
    };
	
	
	const Login = () => {
		if (window.Android) {
			window.Android.login(); // Appeler la fonction Kotlin via l'interface JavaScript
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, 500); 
			navigate('/app');
		} else {
			console.error('Android interface not found.');
		}
    };
	
	useEffect(() => {
        const timer = setTimeout(() => {
			const userId = localStorage.getItem('userId'); // Simulez la récupération de données stockées
			const loggedIn   = localStorage.getItem('logged'); // Simulez la récupération de données stockées
			if(userId & loggedIn){
				//navigate('/app');
			}
			setIsLoading(false);
		}, 500); // 500 millisecondes = 0.5 secondes
    }, [])
	
	
    // Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
    if (isLoading) {
		return <div>
			<><div className="App-header fs-3 fw-normal text-dark p-5">
				<Spinner animation="grow" variant="warning" role="status">
					<span className="visually-hidden text-light">Loading...</span>
				</Spinner>
			</div></> 
		</div>;
    }

    return (
        <div className='App p-3'>
            <div className='App-header'>
                <div className='p-3 text-center'>
                    <br />
                    <img src={logo} style={{ width: '125px' }}alt="Logo" />
                    <h2 className='fw-bold'>Chefferie Bandja </h2>
					<h5 className=''>Initiative de SM Djoumbissie 2 </h5>
	                <p className=''>Plateforme qui vous connecte avec la Chefferie supérieure Bandja </p>
					{/*
					<div>
						{Loading  ? (
							<p>Loading...</p>
						) : (
							contact && (
								<div>
									<h6>Name: {contact.name}</h6>
									<p>Text: {contact.text}</p>
									<p>Country: {contact.country}</p>
								</div>
							)
						)}
                    </div>
                    */}
                    <button className='go' onClick={Login} >
                       C'est compris !
                    </button>
					{/*
					<button className='go' onClick={sendMessageToKotlin2} >
                       I am Understood 2
                    </button>
					*/}
                </div>
            </div>
        </div>
    )
};

export default ConfirmAuth;
