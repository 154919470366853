import React, { useEffect, useState } from "react";
import './Home.css';
import NavBarHome from './NavBarHome';
//import MobileTabs from './../Tabs';
import Spinner from 'react-bootstrap/Spinner';



const Tab1 = () => {


    const [documentData, setDocumentData] = useState(
		JSON.parse(localStorage.getItem('documentPub')) || null
	);
    const [isLoading, setIsLoading] = useState(!documentData);  // Montre un loader si les données ne sont pas en cache

    useEffect(() => {
		// Fonction pour recevoir les données depuis Kotlin
		window.getDocument = (data) => {
		  try {
			// Essayer de parse le JSON reçu si c'est une chaîne
			const parsedData = JSON.parse(data); // Assurez-vous de parser les données si elles sont envoyées en tant que chaîne JSON

			// Vérifier si le document est valide
			if (parsedData) {
			  const newData = parsedData; // Utiliser les données directement
			  const cachedData = JSON.parse(localStorage.getItem('documentPub')) || {}; // Récupérer les données en cache depuis le localStorage

			  // Comparer les nouvelles données avec celles en cache
			  if (JSON.stringify(newData) !== JSON.stringify(cachedData)) {
				setDocumentData(newData);  // Mettre à jour l'état
				localStorage.setItem('documentPub', JSON.stringify(newData));  // Mettre à jour le localStorage
			  }
			  setIsLoading(false); // Arrêter le loader une fois que les données sont récupérées
			} else {
			  console.log('Aucun document trouvé ou erreur de données !');
			  setIsLoading(false);
			}
		  } catch (error) {
			console.error('Erreur lors du traitement des données :', error);
			setIsLoading(false);
		  }
		};

		// Définir la fonction dans l'objet window pour l'appeler depuis Kotlin
		//window.getDocument = getDocument;

		// Appeler la fonction Kotlin via l'interface JavaScript
		if (window.Android && typeof window.Android.getDataFromDoc === 'function') {
		  const docId = 'Dq2IH9qonPF54ryNaZPM'; // Remplacez ce docId par celui que vous voulez
		  window.Android.getDataFromDoc(`publications/${docId}`);
		} else {
		  console.error('Interface Android non disponible');
		}

		// Nettoyer l'effet lorsqu'il est démonté
		return () => {
		  window.getDocument = null;  // Supprimer la fonction de l'objet window pour éviter des fuites de mémoire
		};
    }, []); // Dépendance vide pour s'exécuter uniquement au montage et démontage
	
	const handleImageLoad = () => {
	  // Une fois que l'image est chargée, vous pouvez vérifier si toutes les ressources sont prêtes
	  setIsLoading(false);
	};


    if (isLoading) {
      return <div><><div className="App-header fs-3 fw-normal text-dark p-5">
		  <Spinner animation="grow" variant="warning" role="status">
			  <span className="visually-hidden text-light">Loading...</span>
		  </Spinner>
      </div></> </div>;
    }


    return (

    <div className='App'>
        <NavBarHome />
        <br />
          <div className='col-md-12 mt-4  ' style={{ overflowY: 'hidden'}}>
            <div className='' style={{ overflowY: 'hidden'}}>
               <div className='overlay'>
                <>
                 {documentData ? (
                  <>
                  <div className="text-dark">
                    <h5 className='text-white fw-bolder pt-2'> {documentData.title} </h5>
                    <h6 className='text-light' style={{ whiteSpace: 'pre-wrap', marginTop: '6px' }}>{documentData.content}</h6>
                    <h6 className="fw-normal text-light" style={{ fontSize: '11px' }} >Publié le  { documentData.PublicationtDate }</h6>
                  </div>
                  </>
                 ) : (
                  <div>No data found</div>
                 )}
                </>
               </div>
            </div>
          </div>    
    </div>
    )
};

export default Tab1;
