import React, { useEffect, useState } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import logo from './../../images/logo.png';
import Button from 'react-bootstrap/Button';
import NavBar from './Navbar';
import Spinner from 'react-bootstrap/Spinner';


const FolderI = () => {

    const [message, setMessage]    = useState('');

    //const [imageURL, setImageURL]  = useState('');  // Stocke l'URL de l'image une fois téléchargée

    const [title, setTitle]        = useState('');
	// Création d'un tableau des données à envoyer
    const formData = [title];
	
    const [loading, setLoading]    = useState(false);
	const [isLoading, setIsLoading] = useState(true);  // Montre un loader si les données ne sont pas en cache

    const goBack = () => {
		window.history.back(); 
    };
	
	{/*
	useEffect(() => {
		// Fonction pour recevoir les données depuis Kotlin
		window.getUserDocument = (data) => {
		  try {
			// Essayer de parse le JSON reçu si c'est une chaîne
			const parsedData = JSON.parse(data); // Assurez-vous de parser les données si elles sont envoyées en tant que chaîne JSON

			// Vérifier si le document est valide
			if (parsedData) {
			  const newCacheImg = parsedData.cache_image; // extraire cache image
			  const cachedImage = localStorage.getItem('cache_image') || {}; // Récupérer les données en cache depuis le localStorage

			  // Comparer les nouvelles données avec celles en cache
			  if (newCacheImg !== cachedImage) {
				//setImageURL(newCacheImg);  // Mettre à jour l'état
				localStorage.setItem('cache_image', newCacheImg);  // Mettre à jour le localStorage
			  }
			  setIsLoading(false); // Arrêter le loader une fois que les données sont récupérées
			} else {
			  console.log('Aucun document trouvé ou erreur de données !');
			  setIsLoading(false);
			}
		  } catch (error) {
			console.error('Erreur lors du traitement des données :', error);
			setIsLoading(false);
		  }
		};

		// Appeler la fonction Kotlin via l'interface JavaScript
		if (window.Android && typeof window.Android.getDataFromUserDoc === 'function') {
		  window.Android.getDataFromUserDoc();
		} else {
		  console.error('Interface Android non disponible');
		}

		// Nettoyer l'effet lorsqu'il est démonté
		return () => {
		  window.getUserDocument = null;  // Supprimer la fonction de l'objet window pour éviter des fuites de mémoire
		};
    }, []); // Dépendance vide pour s'exécuter uniquement au montage et démontage
    */}

	const handleSubmit = async (e) => {
		e.preventDefault(); // Empêche le rechargement de la page lors de la soumission
		setLoading(true);    // Active le chargement
		setMessage('');      // Réinitialise le message précédent

		try {
			// Vérifier si l'interface Android est disponible
			if (window.Android && typeof window.Android.addNewDoc === 'function') {
				const collectionPath = 'photos'; // Chemin de la collection Firestore
				
				window.Android.addNewDoc(collectionPath, formData);
				
				setMessage('Album créé avec succès !');
				setTitle(''); // Réinitialise le champ de titre après la soumission
			} else {
				console.error('Interface Android non disponible');
				setMessage('Erreur : Interface Android non disponible');
			}
		} catch (error) {
			// En cas d'erreur, affiche le message d'erreur
			setMessage(`Erreur : ${error.message}`);
		} finally {
			// Désactive le chargement après la soumission
			setLoading(false);
		}
    };
	
	
    return (
        <div className='App container'>
			<nav className="navbar fixed-top">
				<div className='container'>
				    <div className="d-flex">
						<Link className='title fw-bolder' onClick={goBack} >
							<i className="fa-solid fa-arrow-left mt-1 "></i>
						</Link>
						<h5 className='fs-5 ms-3 mt-1 fw-bold'>Nouvel album</h5>
				    </div>
				</div>
			</nav>
            <br />  <br />
            <div className='container'>
                <br /> <br />  
                <form onSubmit={handleSubmit}> 
                    <div className='mb-3'>
                        <label>Nom de l'album</label>
                        <input className='form-control' required value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <button type='submit' disabled={loading} className='btn btn-warning mt-2 btn-lg text-light fw-bold' style={{ width: '100%'}}>
						{loading ? 'Création en cours...' : 'Créer'} <i className='fa-solid fa-plus-circle ms-2'></i>
                    </button>
                </form>
            </div>
            <div className='ps-3 pe-3 mt-2'>
            {message && 
                <p className='fs-6 text-success text-center'>{message}</p>
            }
            </div> 
        </div>
    )
};

export default FolderI;
