import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NavBarBell() {
  return (
    <nav className="navbar fixed-top">
      <div className='container'>
        <div className="">
          <Link className='title' to="/">
            Gallerie
          </Link>
        </div>
        {/* 
        <div className="navbar-right">
          <Link to="/welcome" className="text-secondary fw-bolder me-2" style={{ textDecoration: 'none'}} aria-label="User Account">
            En 
          </Link>
        </div>
        */}
      </div>
    </nav>
  );
}

export default NavBarBell;